

.video-contenedor{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
iframe, .videos{
}
.cuerpo{
  padding-top: 3%;
  background-image: url('../images/evento/EN_VIVO/back_en_vivo.jpg');
  border-image-repeat: repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.contenedor_ponentes{
  padding: 3% 0%;
  background-color: #e2e2e2;
}
.contenedor_ponentes_titulo{
  padding-top: 40px;
}
.texto-preguntas{
  font-size: 0.85em;
  text-align: right;
  color: black;
  height: auto;
  line-height: 1;
  border-radius: 10px;
}
.hora-preguntas{
  font-size: 0.7em;
  text-align: right;
  margin: 0% 5%;
  padding: 0% 1% 1% 1%;
}

.ant-form-item{
  margin-bottom: 1%!important;
}
.btn-enviar{
  color: #083280 !important;
  font-weight: bold;
  background-color: white!important;
  border-radius: 16px!important;
  font-size: 0.8em!important;
  padding: 1px 10px!important;
  border-color: white!important;
}
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
  color:#ffffff!important;
}
.ant-tabs-nav-wrap{
  background-color: #fe633f;
  color:#ffffff;
}
.ant-tabs-tab-btn:focus{
  color:#ffffff!important;
}
.sujeto{
  color: #ffffff;
  font-size: 10px;
  text-align: left;
}

.header-preguntas{
  font-size: 1.2em;
  padding-top: 0px;

}
.pregunta-p{
  margin: 0px!important;
  color: #ffffff;
  padding-top:10px;
  padding-bottom:10px;
}
.img_ponente_home{
  width: 80%;
}
.info_ponente_home{
  font-size: 16px;
  text-align: center;
}
.texto_mayus{
  text-transform: uppercase;
}
@media screen and (min-width:900px) and (max-width:1300px){
  .pregunta-p{
    margin: 0px!important;
    color: #ffffff;
    padding-top:10px;
    padding-bottom:10px;
    font-size: 12px;
  }
}

.ver-preguntas{
  background-color: #ffffff;
  height: 330px;
  overflow: scroll;
}
.area_encuesta{
  background-color: #fe633f;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.area_resultados{
  background-color: #000000;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.text-nom, .text-empre{
  font-size: 0.75em;
  line-height: 1;
}
.preguntas-header{
  background-color: #01356f!important;

}

.contenedor_video {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
}
.contenedor_video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
  z-index: 99;
}
.video2 {
  height: 550px;
}
/*AJUSTES*/

.titulo_webinar{
  font-size: 34px;
  text-align: left;
  margin-bottom: 5px;
  line-height: 1;
  padding-top: 0px;
}

.titulo_webinar_2{
  font-size: 0.6em;
  text-align: left;
  margin-bottom: 0px;
}
.clic_ver{
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  margin-top:0px;
  padding-top: 0px;
  z-index: 98;
}
.tipo_preguntas_select{
  color:#ffffff;
  background-color: #606060;
  border-top: 3px solid #ffffff;
  padding: 3px 0px 2px 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}
.tipo_preguntas{
  color:#ffffff;
  background-color: #606060;
  border: 0px solid #ffffff;
  padding: 3px 0px 2px 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}
.contenedor_enviar{
  padding-top: 10px;
  z-index: 99;
}
.text_enviar{
  margin-left: 0px!important;
  background-color: white;
  color: #083280;
}
.sujeto{
  color: #ffffff;
  font-size: 10px;
  text-align: left;
}
.balloon{
  border-radius: 5px;
  background-color: rgb(230,230,230);
  color: black;
  padding: 10px;
  width: 90%;
  margin-left:5%;
  margin-top:20px;
  margin-bottom:20px;
  display: block;
  position:relative;

  font-size: 1em;
  text-align: right;
  height: auto;
  line-height: 1;
  }

  .balloon:before {
    content:"";
    width: 0;
    height: 0;
    position: absolute;
    bottom:0px;
    right:-10px;
    border-bottom: 20px solid rgb(230,230,230);
    border-right: 20px solid transparent;
    border-left: 0px solid transparent;
    }

@media only screen and (min-width: 991px) {
  

  .imagen_logo{
    height:520px;
  }
}
@media only screen and (max-width: 2600px) {
  .ver-preguntas{
    height: 700px;
  }
}
@media only screen and (max-width: 2400px) {
  .ver-preguntas{
    height: 600px;
  }
  .contenedor_live{
    height: 820px;
  }
  .contenedor_live_preguntas{
    height: 820px;
  }
}
@media only screen and (max-width: 2000px) {
  .ver-preguntas{
    height: 550px;
  }
  .contenedor_live{
    height: 750px;
  }
  .contenedor_live_preguntas{
    height: 750px;
  }
}
@media only screen and (max-width: 1700px) {
  .ver-preguntas{
    height: 500px;
  }
  .contenedor_live{
    height: 700px;
  }
  .contenedor_live_preguntas{
    height: 700px;
  }
}
@media only screen and (max-width: 1550px) {
  .ver-preguntas{
    height: 370px;
  }
  .contenedor_live{
    height: 570px;
  }
  .contenedor_live_preguntas{
    height: 570px;
  }
}
@media only screen and (max-width: 1441px) {
  .ver-preguntas{
    height: 350px;
  }
  .contenedor_live{
    height: 550px;
  }
  .contenedor_live_preguntas{
    height: 550px;
  }
}
@media only screen and (max-width: 1361px) {
  .ver-preguntas{
    height: 320px;
  }
  .contenedor_live{
    height: 520px;
  }
  .contenedor_live_preguntas{
    height: 520px;
  }
}
@media only screen and (max-width: 1300px) {
  .ver-preguntas{
    height: 300px;
  }
  .contenedor_live{
    height: 500px;
  }
  .contenedor_live_preguntas{
    height: 500px;
  }
}
@media only screen and (max-width: 1250px) {
  .ver-preguntas{
    height: 290px;
  }
  .contenedor_live{
    height: 480px;
  }
  .contenedor_live_preguntas{
    height: 480px;
  }
}
@media only screen and (max-width: 1200px) {
  .ver-preguntas{
    height: 270px;
  }
  .contenedor_live{
    height: 480px;
  }
  .contenedor_live_preguntas{
    height: 480px;
  }
}
@media only screen and (max-width: 1161px) {
  .ver-preguntas{
    height: 260px;
  }
  .contenedor_live{
    height: 450px;
  }
  .contenedor_live_preguntas{
    height: 450px;
  }
  .header-preguntas{
    font-size: 1.4em;
  }
  .tipo_preguntas_select{
    font-size: 10px;
  }
  .tipo_preguntas{
    font-size: 10px;
  }
}
@media only screen and (max-width: 1090px) {
  .ver-preguntas{
    height: 230px;
  }
  .contenedor_live{
    height: 420px;
  }
  .contenedor_live_preguntas{
    height: 420px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
  .btn-enviar{
    font-size: 0.7em!important;
    padding: 2px 7px 2px 7px!important;
  }
  .contenedor_aniversario, .contenedor_graficas{
    height: 280px;
  }
}

@media only screen and (max-width: 991px) {
  .video{
    height: 400px;
  }
  .video2 {
    height: 350px;
  }

  .imagen_logo{
    height:350px;
  }
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 540px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .btn-enviar{
    font-size: 0.8em!important;
    padding: 2px 10px 2px 10px!important;
    margin-bottom: 5px;
  }
  .tipo_preguntas_select{
    font-size: 12px;
  }
  .tipo_preguntas{
    font-size: 12px;
  }
  .contenedor_aniversario{
    height: 320px;
  }
  .contenedor_graficas{
    height: 260px;
  }
}
@media only screen and (max-width: 900px) {

  .contenedor_live{
    height: 490px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
}
@media only screen and (max-width: 850px) {
  .contenedor_live{
    height: 470px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 800px) {
  .contenedor_live{
    height: 435px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
}
@media only screen and (max-width: 750px) {
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 410px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
  .info_ponente_home{
    font-size: 14px;
    text-align: center;
    line-height: 1.2;
  }
}
@media only screen and (max-width: 700px) {
  
}
@media only screen and (max-width: 700px) {
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
  .contenedor_live{
    height: 390px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .titulo_webinar{
    font-size: 24px;
    text-align: left;
  }
}
@media only screen and (max-width: 650px) {
  .contenedor_live{
    height: 360px;
  }
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
}
@media only screen and (max-width: 600px) {
  .video{
    height: 300px;
  }
  .video2{
    height: 250px;
  }

  .imagen_logo{
    height:250px;
  }

  .contenedor_live{
    height: 350px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .contenedor_aniversario{
    height: 280px;
  }
  .contenedor_graficas{
    height: 240px;
  }
}
@media only screen and (max-width: 550px) {
  .contenedor_live{
    height: 320px;
  }
  .contenedor_aniversario{
    height: 260px;
  }
}
@media only screen and (max-width: 500px) {
  .contenedor_live{
    height: 290px;
  }
  .titulo_webinar{
    font-size: 1.4em;
    text-align: left;
  }
}
@media only screen and (max-width: 450px) {
  .contenedor_live{
    height: 250px;
  }
  .titulo_webinar{
    font-size: 1.2em;
    text-align: left;
  }
  .clic_ver{
    font-size: 10px;
  }
  .contenedor_aniversario{
    height: 230px;
  }
}
@media only screen and (max-width: 400px) {
  .contenedor_live{
    height: 230px;
  }
  .contenedor_live_preguntas{
    height: 390px;
  }
  .ver-preguntas{
    height: 200px;
  }
  .contenedor_aniversario{
    height: 200px;
  }
}
@media screen and (max-width: 700px) {
  .nombre_tab_menu{
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1000px){
  .nombre_tab_menu{
    font-size: 14px;
  }
}

@media screen and (min-width: 1001px) {
  .nombre_tab_menu{
    font-size: 12px;
  }
}

.encuesta-titulo{
  font-size: 30px;
  font-weight: bold;
}

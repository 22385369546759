/*@font-face{
  font-family: 'Montserrat-Bold';
  src: url ('../TIPOGRAFIAS/monserrat/Montserrat-Bold.otf');
}

@font-face{
  font-family: 'Montserrat-BoldItalic';
  src: url ('../TIPOGRAFIAS/monserrat/Montserrat-BoldItalic.otf');
}
@font-face{
  font-family: 'Montserrat-Regular';
  src: url ('../TIPOGRAFIAS/monserrat/Montserrat-Regular.otf');
}
@font-face{
  font-family: 'Montserrat-SemiBold';
  src: url ('../TIPOGRAFIAS/monserrat/Montserrat-SemiBold.otf');
}*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

body{
  font-family: 'Montserrat' !important;
}

/*.montserrat_bold{
  font-family: Montserrat-Bold !important;
}
.montserrat_italic_bold{
  font-family: Montserrat-BoldItalic !important;
}
.montserrat_regular{
  font-family: Montserrat-Regular !important;
}
.montserrat_semibold{
  font-family: Montserrat-SemiBold !important;
}

.monserrat{
  font-family: Montserrat, sans-serif !important;
}*/

.tamano_12{
  font-size: 15px;
}
.tamano_14{
  font-size: 18px;
}
.tamano_16{
  font-size: 20px;
}
.tamano_18{
  font-size: 24px;
}
.tamano_20{
  font-size: 26px;
}
.tamano_24{
  font-size: 1.5em;
}
.tamano_26{
  font-size: 34px;
}
.tamano_28{
  font-size: 36px;
}
.tamano_32{
  font-size: 41px;
}
.tamano_40{
  font-size: 52px;
}
.tamano_48{
  font-size: 62px;
}
.color_blanco{
  color:#ffffff;
}
.color_azul{
  color: #083280;
}
.color_azul_claro{
  color:#1b69ef;
}
.color_aqua{
  color:#08c9c9;
}
.color_gris_oscuro{
  color:#606060 !important;
}
.color_gris_claro{
  color:#9f9f9f;
}
.back_blanco{
  background-color:#ffffff;
}
.back_azul{
  background-color: #083280;
}
.back_azul_claro{
  background-color: #1b69ef;
}
.back_aqua{
  background-color:#08c9c9;
}
.back_gris_oscuro{
  background-color:#606060;
}
.back_gris_claro{
  background-color:#9f9f9f;
}

.titulo_footer_patrocinadores{
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  padding-top: 3%;
  text-align: left;
}

.textos_titulos_secciones{
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  color: #083280;
  padding-top: 3%;
  text-align: left;
}
.hr_titulos{
  border:none;
  background-color: #1b69ef;
  height: 5px;
  width: 70%;
  text-align: left;
}
.textos_titulos_secciones_no_olvides{
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  color: #083280;
}
.linea_azul{
  height: 10px;
}
.lista_sanidad{
  list-style-image: url('../images/evento/2_HOME/circulo_2.png');
  list-style: 25px;
}
@media screen and (max-width:700px){
  .textos_titulos_secciones{
    font-size: 20px;
    line-height: 20px;
    color: #083280;
    font-weight: bold;
  }
  .titulo_footer_patrocinadores{
    font-size: 20px;
    line-height: 20px;
  }
  .textos_titulos_secciones_no_olvides{
    font-size: 16px;
    line-height: 18px;
  }
}

.contenedor_programas{
    padding-top: 20px;
    background-color: white;
    padding-bottom: 20px;
}

.contenedor_conferencias{
    border-image-repeat: no-repeat;
    background-size: cover;
  }

  .contenedor_video_conferencia {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
}
.contenedor_video_conferencia iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.info-horario_conf{
    margin: 15px;
  }

  .info-fecha_conf{
    font-size: 1.1em;
    color: rgb(25,48,122);
    font-weight: bold;
  }

  .info-anio_conf{
    color:#eb4b1c;
    font-size: 2em;
  }

  .sec-titulo_conf{
    padding-top: 0px;
    }

    .info-completa_conf{
        margin-bottom: 0px!important;
        padding-top: 0px !important;
        text-align: left;
      }

      .info-nombre_conf{
        color:#5c6069;
        font-size: 21px;
      }

      @media screen and (max-width:700px){
        .info-nombre_conf{
          color:#5c6069;
          font-size: 16px;
        }
      }

      .web-pasado_conf{
        width: 50%;
      }

      .info-video_conf{
        color:#000000;
      }

      .info-completa_conf{
        margin-bottom: 0px!important;
        padding-top: 0%;
      }

      .parrafo_experiecia{
        font-size: 20px;
        text-align: center;
      }

      .texto_experiencia_hashtag{
        font-size: 30px;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }

      @media screen and (max-width:700px){
        .texto_experiencia_hashtag{
          font-size: 16px;
        }

        .parrafo_experiecia{
          font-size: 12px;
        }

      }

.titulo-registrar{
  text-align: center;
  font-size: 45px;
  letter-spacing: 3px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 500px) {
  .titulo-registrar{
  font-size: 24px;
  }
}
.titulo-completo{
  text-align: center;
  font-size: 45px;
  color: #01356f;
  letter-spacing: 3px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}
.texto-breve{
  font-size: 24px;
  text-align: center;
}
.correo-azul{
  font-size: 24px;
  text-align: center;
}
.texto-azul{
  color:#414042;
  font-size: 22px;
  text-align: center;
}
.datos-azul{
  font-size: 16px;
  text-align: center;
}
.leyenda{
  font-size: 16px;
  text-align: center;
}
.fondo{
  font-size: 24px;
  padding: 5px;
  color:white;
}
.nota{
  font-size: 14px;
  text-align: center;
}

.texto_inicio_registro{
  font-size: 20px;
  text-align: center;
}

.texto_inicio_registro_hash{
  font-size: 22px;
  text-align: center;
}

.texto_recepcion_virtual{
  text-align: center;
  font-size: 30px;
  color:white;
  padding-left: 15px;
  padding-right: 15px;
}

.img_registrar_re_master{
  width: 500px;
}

.texto_titulo_instruccions{
  font-size: 24px;
}

.contacto_pago{
  text-align:justify;
  font-size:18px;
}

.img_paypal_pago{
  width: 100px;
}

@media screen and (max-width:767px){
  .texto_inicio_registro{
    font-size: 12px;
    text-align: center;
  }

  .texto_inicio_registro_hash{
    font-size: 18px;
    text-align: center;
  }

  .correo-azul{
    font-size: 12px;
    text-align: center;
  }

  .texto_recepcion_virtual{
    text-align: center;
    font-size: 12px;
    color:white;
    padding-right: 10px;
    padding-left: 10px;
  }

  .img_registrar_re_master{
    width: 250px;
  }

  .texto-breve{
    font-size: 12px;
    text-align: center;
  }

  .leyenda{
    font-size: 12px;
    text-align: center;
  }

  .texto_titulo_instruccions{
    font-size: 14px;
  }

  .datos-azul{
    font-size: 12px;
    text-align: center;
  }

  .nota{
    font-size: 10px;
    text-align: center;
  }

  .contacto_pago{
    text-align:left;
    font-size:12px;
  }

  .img_paypal_pago{
    width: 60px;
  }

}

.texto_span_inicio_registro{
  color: #01c7e2;
}

.existe_cuenta_texto{
  text-align: center;
}




.circulo_agenda{
  color: #d5d6d6;
  font-size: 2em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 30px;
}
.borde_agenda{
  border-left: 3px solid #d5d6d6;
}
.circulo_agenda::before{
  content: '';
  position: absolute;
  border-color: #1b69ef;
  border-style: solid;
  border-radius: 50%;
  height: 0.8em;
  top: 40px;
  left: -0.43em;
  margin-top: -1em;
  width: 0.8em;
  background-color: #1b69ef;
}
.text_dias{
  font-size: 30px;
  text-align: left;
  margin: 15px 0px;
  font-weight: bold;
}
.hora_agenda{
  font-size: 22px;
  color: #606060;
  text-align: left;
  line-height: 0px;
  margin:0px;
  padding: 0px;
  line-height: 22px;
}
.titulo_agenda{
  font-size: 28px;
  color: #083280;
  text-align: left;
  margin:0px;
  padding: 0px;
  font-weight: bold;
}
.desc_agenda{
  font-size: 18px;
  color: #606060;
  text-align: left;
  margin:0px;
}
.desc_agenda_desc{
  font-size: 28px;
  color: #606060;
  text-align: justify;
  margin:0px;
}
.linea_agenda_cero{
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}


.linea_agenda{
  border-bottom: 2px solid #d5d6d6;
  padding-bottom: 15px;
}

.img_registrar_master_class{
  width: 400px;
}


@media screen and (max-width:767px){
  .hora_agenda{
    font-size: 12px;
    line-height: 12px;
  }
  .titulo_agenda{
    font-size: 14px;
  }

  .desc_agenda_desc{
    font-size: 12px;
  }

  .desc_agenda{
    font-size: 12px;
  }
  .circulo_agenda{
    padding-left: 20px;
  }

  .img_registrar_master_class{
    width: 150px;
  }

  .text_dias{
    font-size: 18px;
  }

  .circulo_agenda::before{
    height: 0.5em;
    top: 26px;
    left: -0.25em;
    margin-top: -1em;
    width: 0.5em;
    background-color: #1b69ef;
  }

}
.contenedor_conferencista{
  padding: 3% 0% 0% 0%;
}
.contenedor_img_conferencista{
  padding: 0px 0px 5px 0px;
}
.img_conferencista{
  width: 80%;
}
.nombre_conferencista{
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 34px;
  line-height: 30px;
  font-weight: bold;
  color: #083280;
  margin-bottom: 0px;
}
.puesto_conferencista{
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 28px;
  color:#1b69ef;
  margin-bottom: 0px;

}
.p_text{
  text-align: left;
  margin:0px;
  padding:0px;
}
.conferencia_text{
  font-family: 'Montserrat', sans-serif;
  background-color:#1b69ef;
  color:#ffffff;
  font-size: 22px;
  padding: 2px 5px;
}
.parrafo_confeencista{
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
  color:#606060;
  font-size: 16px;
}

@media screen and (max-width:700px){
  .puesto_conferencista{
    font-size: 16px;
  }
  .conferencia_text{
    font-size: 14px;
  }
  .nombre_conferencista{
    font-size: 22px;
  }
  .parrafo_confeencista{
    font-size: 14px;
  }
}